import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';

// ==============================|| LOGO SVG ||============================== //

const LogoFull = ({ reverse }) => {
  const theme = useTheme();
  return (
    <>
      <svg width="223" height="35" viewBox="0 0 223 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.063 -0.727H0.217V0H0.063z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(61.846 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.212-.315V0H.061v-.545h.144v.096h.006a.152.152 0 01.061-.076.189.189 0 01.103-.028c.038 0 .071.009.099.025a.18.18 0 01.066.071.248.248 0 01.023.11V0H.412v-.32c0-.034-.008-.06-.026-.079a.09.09 0 00-.071-.028.108.108 0 00-.054.013.101.101 0 00-.036.038.128.128 0 00-.013.061z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(64.796 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.351-.545v.113H.022v-.113h.329zM.097-.676h.151v.508c0 .014.002.025.006.033a.039.039 0 00.018.016.076.076 0 00.027.005L.32-.116a.075.075 0 01.017-.003l.024.112a.27.27 0 01-.178 0 .127.127 0 01-.064-.052.153.153 0 01-.022-.089v-.528z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(71.33 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.309.011a.29.29 0 01-.145-.035.23.23 0 01-.093-.097.318.318 0 01-.033-.149c0-.056.011-.106.033-.148a.251.251 0 01.092-.099.265.265 0 01.14-.036c.036 0 .07.006.101.018a.228.228 0 01.136.138.345.345 0 01.019.121v.042h-.46v-.094h.318a.121.121 0 00-.014-.058.107.107 0 00-.04-.039.12.12 0 00-.118.001.115.115 0 00-.057.101v.089a.15.15 0 00.015.07c.01.02.024.035.043.046.018.01.04.016.065.016a.172.172 0 00.047-.007.099.099 0 00.035-.022.08.08 0 00.023-.034l.14.009a.202.202 0 01-.043.088.218.218 0 01-.085.058.312.312 0 01-.119.021z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(75.325 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.061 0v-.545h.146v.095h.006a.137.137 0 01.05-.077.133.133 0 01.081-.026l.024.001a.12.12 0 01.023.004v.134a.155.155 0 00-.029-.005.226.226 0 00-.035-.003.115.115 0 00-.059.015.096.096 0 00-.041.039.115.115 0 00-.015.059V0H.061z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(81.827 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.212-.315V0H.061v-.545h.144v.096h.006a.152.152 0 01.061-.076.189.189 0 01.103-.028c.038 0 .071.009.099.025a.18.18 0 01.066.071.248.248 0 01.023.11V0H.412v-.32c0-.034-.008-.06-.026-.079a.09.09 0 00-.071-.028.108.108 0 00-.054.013.101.101 0 00-.036.038.128.128 0 00-.013.061z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(85.981 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.309.011a.29.29 0 01-.145-.035.23.23 0 01-.093-.097.318.318 0 01-.033-.149c0-.056.011-.106.033-.148a.251.251 0 01.092-.099.265.265 0 01.14-.036c.036 0 .07.006.101.018a.228.228 0 01.136.138.345.345 0 01.019.121v.042h-.46v-.094h.318a.121.121 0 00-.014-.058.107.107 0 00-.04-.039.12.12 0 00-.118.001.115.115 0 00-.057.101v.089a.15.15 0 00.015.07c.01.02.024.035.043.046.018.01.04.016.065.016a.172.172 0 00.047-.007.099.099 0 00.035-.022.08.08 0 00.023-.034l.14.009a.202.202 0 01-.043.088.218.218 0 01-.085.058.312.312 0 01-.119.021z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(92.515 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.351-.545v.113H.022v-.113h.329zM.097-.676h.151v.508c0 .014.002.025.006.033a.039.039 0 00.018.016.076.076 0 00.027.005L.32-.116a.075.075 0 01.017-.003l.024.112a.27.27 0 01-.178 0 .127.127 0 01-.064-.052.153.153 0 01-.022-.089v-.528z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(99.016 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.307.011a.274.274 0 01-.143-.036.238.238 0 01-.093-.098.32.32 0 01-.033-.148.32.32 0 01.033-.148.238.238 0 01.093-.098.274.274 0 01.143-.036c.055 0 .103.012.143.036.04.023.071.056.093.098a.332.332 0 01.032.148.332.332 0 01-.032.148.238.238 0 01-.093.098.274.274 0 01-.143.036zm.001-.118A.093.093 0 00.37-.128a.133.133 0 00.039-.059.276.276 0 000-.17.145.145 0 00-.039-.059.093.093 0 00-.062-.021.097.097 0 00-.064.021.136.136 0 00-.039.059.253.253 0 00-.013.085c0 .032.004.06.013.085a.125.125 0 00.039.059.097.097 0 00.064.021z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(106.216 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.354-.545v.113H.017v-.113h.337zM.094 0v-.585c0-.039.008-.072.023-.098a.152.152 0 01.064-.059.21.21 0 01.092-.019.326.326 0 01.107.015l-.027.113a.103.103 0 00-.022-.005.109.109 0 00-.026-.003c-.023 0-.038.006-.047.016a.067.067 0 00-.013.043V0H.094z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(112.876 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.063-.727h.19l.2.488h.009l.2-.488h.189V0H.703v-.473H.696l-.188.47H.407L.218-.475H.212V0H.063v-.727z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(120.044 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <path
          d="M.036-.601v-.126h.597v.126H.41V0H.258v-.601H.036z"
          transform="scale(.96) translate(-76.126 35.215) scale(2.14982) translate(129.97 -4.007) scale(11.1637)"
          fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
        ></path>
        <g>
          <path
            fill="#E5721E"
            d="M240.5 130.25l-37.398-37.398 9.398-9.403-9.102-9.148-18.546 18.551L240.5 148.5l28.898-28.949-9.097-9.102L240.5 130.25"
            transform="scale(.96) translate(-37.594 -7.981) scale(.27103)"
          ></path>
        </g>
        <g>
          <path
            fill="#E5721E"
            d="M277.551 74.352L240.449 37.25l-28.898 28.949 9.148 9.102 19.75-19.801 19.852 19.801 8.148 8.148 9.403 9.449-9.403 9.403 9.102 9.148 18.551-18.551-18.551-18.546"
            transform="scale(.96) translate(-37.594 -7.981) scale(.27103)"
          ></path>
        </g>
        <g>
          <path
            fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.primary.main : '#003d78'}
            d="M285.051 52.5l-14.653 14.648 8.153 8.153 6.5-6.449 25 25-25 25-6.5-6.454-9.102-9.148-9.398-9.398 9.398-9.403-8.148-8.199-17.602 17.602 17.602 17.597 9.097 9.102 14.653 14.648 41.347-41.347L285.051 52.5"
            transform="scale(.96) translate(-37.594 -7.981) scale(.27103)"
          ></path>
        </g>
        <g>
          <path
            fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.primary.main : '#003d78'}
            d="M211.551 66.148L196.898 51.5l-41.347 41.352 41.347 41.347 14.653-14.648-8.199-8.153-6.454 6.454-25-25 25-25 6.454 6.449 9.148 9.148 9.398 9.403-9.398 9.398 8.148 8.199 17.602-17.597-17.602-17.602-9.097-9.102"
            transform="scale(.96) translate(-37.594 -7.981) scale(.27103)"
          ></path>
        </g>
      </svg>
    </>
  );
};

LogoFull.propTypes = {
  reverse: PropTypes.bool
};

export default LogoFull;
