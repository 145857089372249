import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import ErrorRoutes from './ErrorRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, MaintenanceRoutes, ErrorRoutes]);
}
