// import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Container, Toolbar } from '@mui/material';

// project import
import Header from './Header';
import Footer from './Footer';
// import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// import { MenuOrientation } from 'config';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
// import { dispatch } from 'store';
// import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  // const theme = useTheme();
  // const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  // const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { isAuthenticated } = useAuth();

  const { container } = useConfig();

  if (!isAuthenticated) {
    return null;
  }
  // const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  // useEffect(() => {
  //   if (!miniDrawer) {
  //     dispatch(openDrawer(!matchDownXL));
  //   }
  // }, [matchDownXL]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Breadcrumbs navigation={navigation} icon={true} card={false} divider={false} />
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
