import { lazy } from 'react';

// project import
import ROUTES from './routes';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// views routing
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('views/maintenance/coming-soon')));

// ==============================|| AUTH ROUTING ||============================== //

const MaintenanceRoutes = {
  path: '/',
  element: <CommonLayout />,
  children: [
    {
      path: ROUTES.UNDER_CONSTRUCTION,
      element: <MaintenanceUnderConstruction />
    },
    {
      path: ROUTES.COMING_SOON,
      element: <MaintenanceComingSoon />
    }
  ]
};

export default MaintenanceRoutes;
