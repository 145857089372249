// context/SessionContext.js
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

import useIdleTimer from 'hooks/useIdleTimer';
import useAuth from 'hooks/useAuth';
import AlertDialog from 'components/AlertDialog';

import { APP_SESSION_TIMEOUT, APP_SESSION_DIALOG_DURATION } from 'config';

const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
  const { logout, isAuthenticated } = useAuth();
  const [open, setOpen] = useState(false);
  const inactivityTimeoutDuration = APP_SESSION_TIMEOUT;
  const inactivityTimeoutWarningDuration = APP_SESSION_DIALOG_DURATION;

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const { reset, start, stop } = useIdleTimer(logout, inactivityTimeoutDuration, inactivityTimeoutWarningDuration, open, handleDialogOpen);

  useEffect(() => {
    if (isAuthenticated) {
      start();
      document.addEventListener('mousemove', reset);
      document.addEventListener('keydown', reset);
      document.addEventListener('scroll', reset);
      document.addEventListener('click', reset);
    } else {
      stop();
      document.removeEventListener('mousemove', reset);
      document.removeEventListener('keydown', reset);
      document.removeEventListener('scroll', reset);
      document.removeEventListener('click', reset);
    }

    // Cleanup
    return () => {
      stop();
      document.removeEventListener('mousemove', reset);
      document.removeEventListener('keydown', reset);
      document.removeEventListener('scroll', reset);
      document.removeEventListener('click', reset);
    };
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <>
      <AlertDialog onClose={handleDialogClose} open={open} initialTime={inactivityTimeoutWarningDuration} />
      <SessionContext.Provider value={reset}>{children}</SessionContext.Provider>
    </>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node
};

export default SessionContext;
