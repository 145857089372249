// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#E5721E"
        d="M240.5 130.25l-37.398-37.398 9.398-9.403-9.102-9.148-18.546 18.551L240.5 148.5l28.898-28.949-9.097-9.102L240.5 130.25M277.551 74.352L240.449 37.25l-28.898 28.949 9.148 9.102 19.75-19.801 19.852 19.801 8.148 8.148 9.403 9.449-9.403 9.403 9.102 9.148 18.551-18.551-18.551-18.546"
        transform="scale(.96) matrix(.7362 0 0 .7362 -112.918 -3.9)"
      ></path>
      <path
        fill={theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.main : '#003d78'}
        d="M285.051 52.5l-14.653 14.648 8.153 8.153 6.5-6.449 25 25-25 25-6.5-6.454-9.102-9.148-9.398-9.398 9.398-9.403-8.148-8.199-17.602 17.602 17.602 17.597 9.097 9.102 14.653 14.648 41.347-41.347L285.051 52.5M211.551 66.148L196.898 51.5l-41.347 41.352 41.347 41.347 14.653-14.648-8.199-8.153-6.454 6.454-25-25 25-25 6.454 6.449 9.148 9.148 9.398 9.403-9.398 9.398 8.148 8.199 17.602-17.597-17.602-17.602-9.097-9.102"
        transform="scale(.96) matrix(.7362 0 0 .7362 -112.918 -3.9)"
      ></path>
    </svg>
  );
};

export default LogoIcon;
