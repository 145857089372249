// project import
import views from './views';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [views]
};

export default menuItems;
