import { useCallback, useEffect, useRef, useState } from 'react';

function useIdleTimer(callback, timeoutDuration, dialogDuration, open, handleDialogOpen) {
  const [isActive, setIsActive] = useState(false);
  const timerRef = useRef(null);
  const intervalRef = useRef(null);
  const elapsedTimeRef = useRef(0);

  const stop = useCallback(() => {
    if (isActive) {
      clearTimeout(timerRef.current);
      clearInterval(intervalRef.current);
      setIsActive(false);
    }
  }, [isActive]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearInterval(intervalRef.current);
    };
  }, []);

  const start = useCallback(() => {
    if (!isActive) {
      elapsedTimeRef.current = 0;
      timerRef.current = setTimeout(() => {
        stop();
        callback();
      }, timeoutDuration);

      intervalRef.current = setInterval(() => {
        elapsedTimeRef.current += 1000;

        if (timeoutDuration - elapsedTimeRef.current <= dialogDuration * 1000 && !open) {
          handleDialogOpen();
        }
      }, 1000);

      setIsActive(true);
    }
  }, [isActive, timeoutDuration, dialogDuration, stop, callback, open, handleDialogOpen]);

  const reset = useCallback(() => {
    clearTimeout(timerRef.current);
    elapsedTimeRef.current = 0;
    timerRef.current = setTimeout(() => {
      stop();
      callback();
    }, timeoutDuration);
  }, [callback, stop, timeoutDuration]);

  return { reset, start, stop };
}

export default useIdleTimer;
