import { lazy } from 'react';

// project import
import ROUTES from './routes';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CommonLayout from 'layout/CommonLayout';

// render - sample page
const DashboardView = lazy(() => import('views/project/dashboard'));
const RunView = lazy(() => import('views/project/run-dashboard'));
const AuthVerifyMail = Loadable(lazy(() => import('views/auth/verify-mail')));
const AuthAccountDisabled = Loadable(lazy(() => import('views/auth/account-disabled')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.ROOT,
          element: <DashboardView />
        },
        {
          path: ROUTES.RUN_MAIN,
          element: <RunView />
        }
      ]
    },
    {
      path: '',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.AUTH.VERIFY_EMAIL,
          element: <AuthVerifyMail />
        },
        {
          path: ROUTES.AUTH.ACCOUNT_DISABLED,
          element: <AuthAccountDisabled />
        }
      ]
    }
  ]
};

export default MainRoutes;
