import { useCallback, useState, useEffect } from 'react';

// material-ui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
// ==============================|| DIALOG - ALERT ||============================== //

export default function AlertDialog({ onClose, open, initialTime }) {
  // const [open, setOpen] = useState(true);
  const [countdown, setCountdown] = useState(initialTime);
  const { logout } = useAuth();

  const handleLogout = useCallback(() => {
    logout();
    onClose();
  }, [logout, onClose]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setCountdown(initialTime);
    }
  }, [open, initialTime]);

  useEffect(() => {
    if (open && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      // When countdown reaches 0, handle the event (e.g., auto logout)
      handleLogout();
      setCountdown(initialTime);
    }
  }, [countdown, open, handleLogout, initialTime]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle id="alert-dialog-title">Session Timeout Warning</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be logged out in {countdown} seconds due to inactivity. Please choose one of the following options.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogout}>Logout Now</Button>
            <Button variant="contained" onClick={handleClose}>
              Extend my Session
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

AlertDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  initialTime: PropTypes.number
};
