import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import ROUTES from 'routes/routes';
import useAuth from 'hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isAuthenticated, isVerified } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname !== ROUTES.AUTH.REGISTER) {
        const newRoute = isVerified ? ROUTES.ROOT : ROUTES.AUTH.VERIFY_EMAIL;

        navigate(location?.state?.from ? location?.state?.from : newRoute, {
          state: {
            from: ''
          },
          replace: true
        });
      }
    }
  }, [isAuthenticated, isVerified, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
