import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT, NOTVERIFIED } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import { capitalizeFirstLetter } from 'utils/stringUtils';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  });
}

// const
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerified: false,
  isDisabled: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      // console.log('onAuthStateChanged', firebaseUser);
      if (firebaseUser) {
        const result = await firebaseUser.getIdTokenResult();
        const user = {
          id: firebaseUser.uid,
          email: firebaseUser.email,
          name: firebaseUser.displayName || 'Maximus Meridius',
          role: capitalizeFirstLetter(result.claims.role || 'guest')
        };

        if (!firebaseUser.emailVerified) {
          dispatch({
            type: NOTVERIFIED,
            payload: { user }
          });
        } else {
          dispatch({
            type: LOGIN,
            payload: { user }
          });
        }
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    });
  }, [dispatch]);

  const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseRegister = async (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Add display name
        userCredential.user.updateProfile({
          displayName: `${firstName} ${lastName}`
        });

        const payload = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          firstName: firstName,
          lastName: lastName
        };
        console.log(payload);
        // TODO: Create new user in iomt_admin

        // TODO: Verify send verification mail, error notification, delete user.
        sendVerificationEmail();
        // if ( sendVerificationEmail() ) {
        //   return userCredential.user;
        // };
      });

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Sign-out successful.');
      });
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const emailVerification = async (actionCode) => {
    await sleep(2000); // waits for 3 seconds
    try {
      await firebase.auth().applyActionCode(actionCode);
      return true;
    } catch (error) {
      console.log('firebaseEmailVerification', error);
      return false;
    }
  };

  const sendVerificationEmail = () => {
    const user = firebase.auth().currentUser;
    // let actionCodeSettings = {
    //   url: process.env.REACT_APP_FIREBASE_REDIRECT_URL + 'email-verification/?email=' + user.email,
    //   handleCodeInApp: false
    // };
    user
      .sendEmailVerification()
      .then(() => {
        console.log('Verification email sent successfully.');
        return true;
      })
      .catch((error) => {
        console.error('Error sending verification email:', error);
        return false;
      });
  };

  const getIDToken = (user) => {
    // const user = firebase.auth().currentUser;
    if (user) {
      return user.getIdTokenResult(true);
    }
    return null;
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        emailVerification,
        getIDToken,
        login: () => {},
        logout,
        resetPassword,
        sendVerificationEmail,
        updateProfile
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
