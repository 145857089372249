import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();
  return (
    <svg width="118" height="35" viewBox="0 0 118 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="58.352"
        y="9.147"
        width="3.689"
        height="17.455"
        fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
      />
      <path d="M58.352 9.147H62.041V26.601999999999997H58.352z">
        fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
      </path>
      <path
        d="M70.537 26.853c-1.326 0-2.469-.282-3.432-.846a5.729 5.729 0 01-2.228-2.366c-.522-1.013-.784-2.192-.784-3.538 0-1.35.262-2.532.784-3.548a5.724 5.724 0 012.228-2.369c.963-.564 2.106-.846 3.432-.846 1.325 0 2.468.282 3.431.846a5.724 5.724 0 012.228 2.369c.522 1.016.784 2.198.784 3.548 0 1.346-.262 2.525-.784 3.538a5.729 5.729 0 01-2.228 2.366c-.963.564-2.106.846-3.431.846zm.02-2.809c.598 0 1.099-.172 1.505-.516.406-.344.712-.815.919-1.412.207-.596.31-1.274.31-2.033 0-.763-.103-1.442-.31-2.04-.207-.597-.513-1.069-.919-1.415-.406-.347-.907-.52-1.505-.52-.611 0-1.123.173-1.536.52-.412.346-.722.818-.929 1.415-.207.598-.31 1.277-.31 2.04 0 .759.103 1.437.31 2.033.207.597.517 1.068.929 1.412.413.344.925.516 1.536.516zM79.012 9.147h4.547l4.807 11.727h.205l4.806-11.727h4.548v17.454h-3.573V15.242h-.15l-4.513 11.277h-2.441l-4.514-11.318h-.15v11.4h-3.572V9.147zM99.684 12.188V9.147h14.339v3.04h-5.346v14.414h-3.647V12.188h-5.346z"
        fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.common.white : theme.palette.common.black}
      ></path>
      <path
        fill="#e5721e"
        d="M27.59 27.321L17.454 17.185l2.547-2.549-2.467-2.479-5.027 5.028L27.59 32.267l7.832-7.846-2.466-2.467-5.366 5.367M37.632 12.17L27.576 2.115l-7.832 7.846 2.479 2.467 5.353-5.367 5.38 5.367 2.209 2.208 2.548 2.561-2.548 2.549 2.467 2.479 5.028-5.028-5.028-5.027"
      ></path>
      <path
        d="M39.664 6.248l-3.971 3.97 2.21 2.21 1.761-1.748 6.776 6.776-6.776 6.775-1.761-1.749-2.467-2.479-2.547-2.547 2.547-2.549-2.209-2.222-4.77 4.771 4.77 4.769 2.466 2.467 3.971 3.97 11.207-11.206L39.664 6.248M19.744 9.947l-3.972-3.97L4.566 17.185l11.206 11.206 3.972-3.97-2.223-2.21-1.749 1.749-6.776-6.775 6.776-6.776 1.749 1.748 2.48 2.479 2.547 2.549-2.547 2.547 2.208 2.222 4.771-4.769-4.771-4.771-2.465-2.467"
        fill={theme.palette.mode === ThemeMode.DARK || reverse ? theme.palette.primary.main : '#003d78'}
      ></path>
    </svg>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
