import { lazy } from 'react';

// project import
import ROUTES from 'routes/routes';
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('views/auth/login')));
const AuthRegister = Loadable(lazy(() => import('views/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('views/auth/check-mail')));
const AuthVerifyMail = Loadable(lazy(() => import('views/auth/verify-mail')));
const AuthAction = Loadable(lazy(() => import('views/auth/auth-action')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: ROUTES.AUTH.LOGIN,
      element: <AuthLogin />
    },
    {
      path: ROUTES.AUTH.REGISTER,
      element: <AuthRegister />
    },
    {
      path: ROUTES.AUTH.FORGOT_PASSWORD,
      element: <AuthForgotPassword />
    },
    {
      path: ROUTES.AUTH.CHECK_EMAIL,
      element: <AuthCheckMail />
    },
    {
      path: ROUTES.AUTH.VERIFY_EMAIL,
      element: <AuthVerifyMail />
    },
    {
      path: ROUTES.AUTH.AUTH_ACTION,
      element: <AuthAction />
    }
  ]
};

export default LoginRoutes;
