import { lazy } from 'react';

// project import
import ROUTES from './routes';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const Error401View = Loadable(lazy(() => import('views/errors/404')));
const Error404View = Loadable(lazy(() => import('views/errors/404')));
const Error500View = Loadable(lazy(() => import('views/errors/500')));

// ==============================|| AUTH ROUTING ||============================== //

const ErrorRoutes = {
  path: '/',
  element: <CommonLayout />,
  children: [
    {
      path: ROUTES.E401,
      element: <Error401View />
    },
    {
      path: ROUTES.E404,
      element: <Error404View />
    },
    {
      path: ROUTES.E500,
      element: <Error500View />
    },
    {
      path: '*',
      element: <Error404View />
    }
  ]
};

export default ErrorRoutes;
