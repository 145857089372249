const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHECK_EMAIL: '/check-email',
  VERIFY_EMAIL: '/verify-email',
  ACCOUNT_DISABLED: '/account-disabled',
  AUTH_ACTION: '/auth-action'
};

const ROUTES = {
  ROOT: '/',
  RUN_DASHBOARD: '/run-dashboard',

  AUTH: AUTH_ROUTES,

  E404: '/404',
  E401: '/401',
  E500: '/500',
  COMING_SOON: '/coming-soon',
  UNDER_CONSTRUCTION: '/under-construction'
};

export default ROUTES;
