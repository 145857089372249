import { useMemo } from 'react';
// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import Logo from 'components/logo';
import ThemeModeToggler from 'components/ThemeModeToggler';
import Profile from './Profile';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  // const { mode } = useConfig();

  const themeMode = useMemo(() => <ThemeModeToggler />, []);

  return (
    <>
      <Logo isFull={true} />
      <Box sx={{ width: '100%', ml: 1 }} />
      {!matchesXs && themeMode}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
