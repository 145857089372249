// third-party
import { FormattedMessage } from 'react-intl';

// project
import ROUTES from 'routes/routes';

// assets
import { DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined } from '@ant-design/icons';

// icons
const icons = { DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const views = {
  id: 'group-pages',
  title: <FormattedMessage id="views" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'collapse',
      icon: icons.LoginOutlined,
      url: ROUTES.ROOT,
      children: [
        {
          id: 'run-dashboard',
          title: <FormattedMessage id="run-dashboard" />,
          type: 'item',
          url: ROUTES.RUN_DASHBOARD,
          target: true
        }
      ]
    }
  ]
};

export default views;
